<template>
  <v-list>
    <v-card class="mb-2" @click.stop="">
      <v-list-item>
      <v-list-item-avatar color="error lighten-1">
        <v-icon dark>mdi-alert-circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">
          file-import-example.csv
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-chip-group column>
            <v-chip>
              <v-icon left>mdi-calendar</v-icon>
              24.10.2020
            </v-chip>
            <v-chip>
              <v-icon left>mdi-account</v-icon>
              Буняк В.С.
            </v-chip>
          </v-chip-group>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div class="subtitle-1">
          Рядків: <span class="secondary--text font-weight-medium">100</span>
        </div>
        <div class="subtitle-1">
          Успішно: <span class="success--text font-weight-medium">90</span>
        </div>
        <div class="subtitle-1">
          Помилок: <span class="error--text font-weight-medium">10</span>
        </div>
      </v-list-item-action>
    </v-list-item>
    </v-card>
    <v-card class="mb-2" @click.stop="">
      <v-list-item>
        <v-list-item-avatar color="success lighten-1">
          <v-icon dark>mdi-checkbox-marked-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            file-import-example.csv
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-chip-group column>
              <v-chip>
                <v-icon left>mdi-calendar</v-icon>
                24.10.2020
              </v-chip>
              <v-chip>
                <v-icon left>mdi-account</v-icon>
                Буняк В.С.
              </v-chip>
            </v-chip-group>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="subtitle-1">
            Рядків: <span class="secondary--text font-weight-medium">100</span>
          </div>
          <div class="subtitle-1">
            Успішно: <span class="success--text font-weight-medium">90</span>
          </div>
          <div class="subtitle-1">
            Помилок: <span class="error--text font-weight-medium">10</span>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-list>
</template>

<script>
export default {
name: "import_header"
}
</script>

<style scoped>

</style>