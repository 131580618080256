<template>
  <v-container fluid class="pt-0">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card tile elevation="1">
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Імпорт квартир/абонентів
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
        </v-card>

            <v-row>
              <v-col cols="12" md="4">
                <v-card class="fill-height">
                  <v-card-text>
                    <p>Тут Ви зможете самостійно завантажити дані у систему. Нижче є посилання на приклад файлу-імпорту. Будь
                      ласка заповніть його так як показано у прикладі</p>
                    <v-btn class="mb-3" depressed color="success" block tag="a" download
                           :href="`${publicPath}files/flat-import-example.csv`" blank>
                      <v-icon left>mdi-file-document-outline</v-icon>
                      Приклад файлу (CSV)
                    </v-btn>
                    <v-btn depressed color="secondary" block tag="a" download
                           :href="`${publicPath}files/flat-import-example.xls`" blank>
                      <v-icon left>mdi-file-excel-outline</v-icon>
                      Приклад файлу (EXSEL)
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="8" class="#fffff">
                <v-card class="fill-height">
                  <div>
                    <v-card-text>
                      <v-file-input
                          v-model="inputFile"
                          placeholder="Оберіть файл для імпорту абонентів"
                          label="Файл з абонентами"
                          prepend-icon="mdi-paperclip"
                          show-size
                          hide-details
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                              small
                              label
                              color="success"
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-card-text>
                    <v-card-text>
                      <v-btn depressed color="grey lighten-1" block :disabled="!inputFile" @click.stop="importFile">Завантажити</v-btn>
                    </v-card-text>
                    <v-card-text>
                      <ImportHeader/>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
            </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import importAPI from '@/utils/axios/import_files'
import ImportHeader from '@/components/import/ImportHeader'

export default {
  name: "FlatImport",
  components: {
    ImportHeader
  },
  data() {
    return {
      inputFile: null,
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    importFile() {
      const file = this.inputFile
      importAPI.flat_import(file)
          .then(res => res.data)
          .then(data => {
            console.log(data)
          })
          .catch(err => {
            console.log(err)
          })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
